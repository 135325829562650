<template>
    <div class="px-3 py-2"> 
        <v-form
            ref="forinv"
            lazy-validation
        >
        <b-sidebar id="pos_addinvoice" aria-labelledby="sidebar-no-header-title" width="500px" no-header backdrop :style="`direction:`+$parent.lang.ldir" right :title="$parent.lang.add_invoice" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{$parent.lang.add_invoice}}</span>
                    </div>
                    <div @click="hide" id="iHideMe" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>{{$parent.lang.close}}</span>
                </div>
                </div>
                <v-divider></v-divider>
                <div class="px-3 py-2" style="direction:rtl">
                    <v-row>
                        <v-col cols="6">
                            <label>{{$parent.lang.payment_method}}</label>
                            <b-form-select
                                v-model="$parent.paytype"
                                :options="$parent.paytypes"
                                class="selborder"
                                :label="$parent.lang.payment_method"
                                ></b-form-select>
                        </v-col>
                        <v-col cols="6">
                            <label>{{$parent.lang.invoice_type}}</label>
                            <b-form-select
                                v-model="$parent.invtype"
                                :options="$parent.invtypes"
                                class="selborder"
                                :label="$parent.lang.invoice_type"
                                @change="changePtype()"
                                ></b-form-select>
                        </v-col>
                        <v-col cols="6">
                            <label>{{$parent.lang.paid_amount}}</label>
                            <b-form-input
                                :label="$parent.lang.paid_amount"
                                v-model="$parent.paidamount"
                                class="inborder"
                                type="number"
                                >{{ $parent.paidamount }}</b-form-input>
                        </v-col>
                    </v-row>
                </div>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="ihide(), $parent.addInvoice()" hide id="addBTN" variant="success" class="ma-2 btn-sm" style="width:130px;">{{$parent.lang.create_invoice}}</b-button>
                </div>
            </template>
        </b-sidebar>
        </v-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        ihide(){
            document.getElementById('iHideMe').click();
        },
        
    },
    
}
</script>